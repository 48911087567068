(function() {
    'use strict';

    angular
        .module('pickerApp')
        .controller('ModalImportFormRecordsCtrl', ModalImportFormRecordsCtrl);

    ModalImportFormRecordsCtrl.$inject = ['$rootScope','$scope', 'Principal', 'LoginService', 'FormRecord', 'Form', '$state', '$filter','$uibModalInstance', 'rows', 'headers', 'form', '$sce', 'Upload', '$q', '$http', 'ModalHelper', '$timeout'];

    function ModalImportFormRecordsCtrl ($rootScope, $scope, Principal, LoginService, FormRecord, Form, $state, $filter, $uibModalInstance, rows, headers, form, $sce, Upload, $q, $http, ModalHelper, $timeout) {
        var vm = this;
        vm.form = form;
        vm.rows = rows;
        vm.headers = headers;
        vm.addressField = $filter("filter")(vm.form.fieldSets[0].items, {type: "address"})[0];
        vm.mapField = $filter("filter")(vm.form.fieldSets[0].items, {type: "map"})[0];
        
        var getFilterBounds = function(text){
          if (text && text.match('|')) {
            var points = text.split("|");
            var s, n, e, w;
            n = parseFloat(points[1].split(",")[0]);
            s = parseFloat(points[0].split(",")[0]);
            e = parseFloat(points[0].split(",")[1]);
            w = parseFloat(points[1].split(",")[1]);
            if(Number.isNaN(n)|| Number.isNaN(s)|| Number.isNaN(w)|| Number.isNaN(e)){
              throw "Parse exception, wrong rectangle value";
            }else{
              return new google.maps.LatLngBounds({lat: s, lng: w},{lat: n, lng: e});  
            }
          }
        }

        vm.bounds = getFilterBounds(vm.form.properties.zoneRectangle);

        var initialize = function(){
          $rootScope.showLoading("Analizando respuestas. Completado 0 de "+vm.rows.length+".");
          var deferred = $q.defer();
          var loopPromises = [deferred.promise];
          if(vm.addressField){
            $timeout(function(){
              for (var index = 0; index < vm.rows.length; index++) {
                var loopDeferred = $q.defer();
                loopPromises.push(loopDeferred.promise);
                vm.rows[index].invalid = isFormRecordInvalid(vm.rows[index]);
                loopDeferred.resolve();
                $rootScope.updateTextLoading("Analizando respuestas. Completado "+ (index+1) +" de "+vm.rows.length + ".")
              };
            }, 1000)
          }else{
            for (var index = 0; index < vm.rows.length; index++) {
              var loopDeferred = $q.defer();
              loopPromises.push(loopDeferred.promise);
              vm.rows[index].invalid = isFormRecordInvalid(vm.rows[index]);
              loopDeferred.resolve();
              $rootScope.updateTextLoading("Analizando respuestas. Completado "+ (index+1) +" de "+vm.rows.length + ".");
            }
          }
          if(vm.rows.length > 0){
            $timeout(function(){
              deferred.resolve();
            }, vm.addressField ? 2000 : 500); 
          }
          $q.all(loopPromises).then(function(){
            $rootScope.hideLoading();
            setImportDisabled();
          })
        }

        vm.cancel = function () {
          $uibModalInstance.dismiss('canceled');
        };

        var isFormRecordInvalid = function(row){
          var error = false;
          var textError = '';
          
          if (vm.form) {
            for(var j = 0; j<vm.form.fieldSets[0].items.length; j++) {
              var item = vm.form.fieldSets[0].items[j];
              var isValid = true;
              
              if(item.type == "map" || item.type == "text" || item.type == "select" || item.type == "date" || item.type == "address") {
                var itemRecordValue = item.type == "map" ? row["Latitud"]+"|"+row["Longitud"] : row[item.label];
                itemRecordValue = itemRecordValue + "";
                if (item.required && (!itemRecordValue || itemRecordValue == "" || itemRecordValue == [] || (item.type == "map" && ((row["Latitud"] == "" || row["Longitud"] == "") && !vm.addressField)))) {
                  isValid = false;
                  textError = textError + item.label + ' es requerido, ';
                } else {
                  switch(item.type){
                    case "map":
                      var lat = parseFloat(row["Latitud"]);
                      var lng = parseFloat(row["Longitud"]);
                      if(Number.isNaN(lat) || Number.isNaN(lng)){
                        getAddress(row).then(function(latLng) {
                          if(latLng){
                            row["Latitud"] = latLng.lat;
                            row["Longitud"] = latLng.lng;
                          }else{
                            isValid = false;
                            textError = textError + item.label + ' la dirección no fue encontrada, ';
                          }
                        }, function(error){
                          textError = textError + item.label + ' error al buscar la dirección, ';
                          isValid = false;
                        });
                      }
                      break;
                    case "select":
                      var checked = false;
                      for(var i = 0; i<item.values.length; i++){
                        if (itemRecordValue != null && itemRecordValue != '' && itemRecordValue != undefined) {
                          if(item.values[i].label.toLowerCase().replace(/\s+/g, '') == itemRecordValue.toLowerCase().replace(/\s+/g, '')){
                            row[item.label] = item.values[i].label;
                            checked = true;
                            break;
                          } 
                        } else {
                          checked = true;
                          break;
                        }
                      }
                      isValid = checked;
                      if (!isValid) {
                        textError = textError + 'En el campo ' +item.label + ', la opción no se encuentra dentro de las habilitadas, ';
                      }
                      break;
                    case "date":
                      row[item.label] = $filter('date')(row[item.label], "dd/MM/yyyy");
                      break;
                    case "text":
                      if(item.subtype == "number" && itemRecordValue != ""){
                        var value = parseFloat(itemRecordValue);
                        if(Number.isNaN(value)){
                          textError = textError + item.label + ' no es un número, ';
                          isValid = false;
                        }else if(value>item.max || value<item.min){
                          textError = textError + item.label + ' no es un número válido dentro del rango permitido, ';
                          isValid = false;
                        }else if(value.toString().length>255){
                          textError = textError + item.label + ' el numero es más largo que el permitido (255 digitos), '
                          isValid = false;
                        }
                      }else if(itemRecordValue.length > 255){
                        textError = textError + item.label + ' el texto es más largo que el permitido (255 caracteres), '
                        isValid = false;
                      }
                      break;
                  }
                }
                if(!isValid && !error){
                  error = true;
                }
              }
            }
          }
          return { error: error, textError: textError };
        }

        function getAddress(row) {
          var deferred = $q.defer();
          if(vm.addressField && row[vm.addressField.label] && row[vm.addressField.label] !== ""){
            var geocoder = new google.maps.Geocoder();
            if(geocoder){
                geocoder.geocode({ address: row[vm.addressField.label] }, function(results, status){
                    if (status === "OK") {
                        if (results[0]) {
                          var latLng = {
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng()
                          };
                          deferred.resolve(latLng);
                        } else {
                          deferred.resolve(null);
                        }
                    } else {
                      deferred.resolve(null);
                    }
                });
            }else{
              deferred.resolve(null);
            }
          }
          return deferred.promise;
        }

        function setImportDisabled(){
          var rowsInvalid = $filter('filter')(vm.rows, {invalid: {error: true}});
          if(rowsInvalid.length>0){
            vm.importDisabled = true;
          }else{
            vm.importDisabled = false;
          }
        }

        vm.deleteAll = function(){
          vm.rows = $filter('filter')(vm.rows, {invalid: { error: false }});
          vm.importDisabled = false;
        }

        vm.submit = function(){
          $rootScope.showLoading("Importando respuestas. Completado 0 de "+vm.rows.length+".");
          var sequence = $q.defer();
          sequence.resolve();
          sequence = sequence.promise;
          var loopPromises = [];
          angular.forEach(vm.rows, function(row, index){
            var loopDeferred = $q.defer();
            loopPromises.push(loopDeferred.promise);
            sequence = sequence.then(function() {
              loopDeferred.resolve();
              $rootScope.updateTextLoading("Importando respuestas. Completado "+(index+1)+" de "+vm.rows.length+".");
              return saveFormRecord(row);
            });
          })
          $q.all(loopPromises).then(function(){
            $rootScope.hideLoading();
            vm.ok();
          })
        }

        var saveFormRecord = function(row){
          var formRecord = {
            fieldSetRecords: [{
              fieldSetId: vm.form.fieldSets[0].id,
              itemRecords: []
            }],
            id: null,
            formId: vm.form.id
          };
          for (var j = 0; j < vm.form.fieldSets[0].items.length; j++) {
            var item = vm.form.fieldSets[0].items[j];
            if(row.hasOwnProperty(item.label)){
              if(item.type=="select"){
                for(var i = 0; i<item.values.length; i++){
                  if(item.values[i].label.toLowerCase().replace(/\s+/g, '') == row[item.label].toLowerCase().replace(/\s+/g, '')){
                    formRecord.fieldSetRecords[0].itemRecords.push({itemId: item.id, value: item.values[i].value });
                    break;
                  }
                }
              }else if(item.type=="date"){
                formRecord.fieldSetRecords[0].itemRecords.push({itemId: item.id, value: row[item.label] instanceof Date ? row[item.label].toISOString(): row[item.label] })
              }else if(item.type=="textarea"){
                formRecord.fieldSetRecords[0].itemRecords.push({itemId: item.id, value: null, extraItemRecord: {longValue: row[item.label]}})
              }else{
                formRecord.fieldSetRecords[0].itemRecords.push({itemId: item.id, value: row[item.label]});
              }
            }else if(item.type == "map"){
              formRecord.fieldSetRecords[0].itemRecords.push({itemId: item.id, value: row["Latitud"]+"|"+row["Longitud"]});
            }else{
              formRecord.fieldSetRecords[0].itemRecords.push({itemId: item.id});
            }
          }
          formRecord.createdAt = (new Date()).toISOString();
          return FormRecord.save({},formRecord).$promise
        }

        vm.ok = function () {
          $uibModalInstance.close('ok');
        };

        vm.editPosition = function(row){
          var modalInstance = ModalHelper.open(
            'app/entities/form-record/modal-edit-position-form-records.html',
            'ModalEditPositionFormRecordsCtrl',
            {
              position: function(){
                if(row["Latitud"] && row["Longitud"]){
                  return row["Latitud"]+"|"+row["Longitud"];  
                }else if(vm.form.properties.defaultMapCenter) {
                  return vm.form.properties.defaultMapCenter;
                }else{
                  return undefined;
                }
                
              }
            }
          ) 

          modalInstance.result.then(function (positionStr) {
              if(positionStr != null && positionStr != "canceled"){
                var latLngArray = positionStr.split('|');
                row["Latitud"] = latLngArray[0];
                row["Longitud"] = latLngArray[1];
              }
            }, function (error) {
          });
        }

        initialize();
    }

})();
