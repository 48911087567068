 (function() {
    'use strict';

    angular
        .module('pickerApp')
        .controller('ModalFormRecordMasiveUpdate', ModalFormRecordMasiveUpdate);

    ModalFormRecordMasiveUpdate.$inject = ['$rootScope', '$scope', 'Principal', 'LoginService', 'ItemTemplateService','FormRecord', 'ItemRecord', 'DocumentRecord', '$state', '$filter','$uibModalInstance', 'form', 'formRecords', 'actionId', '$sce', 'Upload', '$q', '$timeout', '$compile'];

    function ModalFormRecordMasiveUpdate ($rootScope, $scope, Principal, LoginService, ItemTemplateService,FormRecord, ItemRecord, DocumentRecord, $state, $filter, $uibModalInstance, form, formRecords, actionId, $sce, Upload, $q, $timeout, $compile) {;
        var vm = this;
        vm.form = form;
        vm.columnsToRenderRight = [];
        vm.columnsToRenderLeft = [];
        vm.itemRecords = {};
        vm.files = {};
        vm.formRecords = formRecords;
        vm.actionId = actionId;
        vm.errors = {};

        vm.populateFormRecord = function(formRecord){
          var deferred = $q.defer();
          var loopPromises = [];
          var formRecord;
          var itemRecords = [];
          angular.forEach(formRecord.fieldSetRecords, function(fieldSetRecord){
            angular.forEach(fieldSetRecord.itemRecords, function(itemRecord){
              itemRecords.push(itemRecord);
            })
          })
          angular.forEach(vm.form.fieldSets, function(fieldSet){
            angular.forEach(fieldSet.items, function(itemForm){
              var itemRecordTemp = $filter('filter')(itemRecords, {itemId: itemForm.id})[0];
              if(angular.isDefined(itemRecordTemp)){
                var loopDeferred = $q.defer();
                loopPromises.push(loopDeferred.promise);
                itemRecordTemp.name = itemForm.name;
                itemRecordTemp.label = itemForm.label;
                itemRecordTemp.type = itemForm.type;
                switch(itemRecordTemp.type){
                  case 'map':
                    var splitValues = itemRecordTemp.value.split('|');
                    itemRecordTemp.markers = {
                      currentPosition: {
                        lat: parseFloat(splitValues[0]),
                        lng: parseFloat(splitValues[1]),
                        focus: false,
                        draggable: false
                      }
                    }
                    itemRecordTemp.center = {
                      lat: parseFloat(splitValues[0]),
                      lng: parseFloat(splitValues[1]),
                      zoom: 17
                    }
                    loopDeferred.resolve();
                  break;
                  case 'image':
                  case 'drawing':
                  case 'file':
                    var secondLoopPromises = []
                    ItemRecord.getDocumentRecords({id: itemRecordTemp.id}).$promise.then(function(response){
                      itemRecordTemp.documentRecords = [];
                      if(response.length == 0){
                          loopDeferred.resolve();
                      }else{
                        angular.forEach(response, function(documentRecord){
                          var secondLoopDeferred = $q.defer();
                          secondLoopPromises.push(secondLoopDeferred.promise);
                          DocumentRecord.get({id: documentRecord.id}).$promise.then(function(response){
                            $timeout(function() {
                              itemRecordTemp.documentRecords.push(response)
                              secondLoopDeferred.resolve();
                            }, 100);
                          }, function(error){
                            console.log(error);
                            secondLoopDeferred.resolve()
                          })
                        })
                        $q.all(secondLoopPromises).then(function(){
                          loopDeferred.resolve();
                        })  
                      }
                    }, function(error){
                      console.log(error);
                      loopDeferred.resolve();
                    })
                  break;
                  case 'select':
                      if(itemForm.parentItemId){
                        var itemParent = $filter('filter')(vm.form.fieldSets[0].items, {name: itemForm.parentItemId}, true)[0];
                        var itemRecordParent = $filter('filter')(formRecord.fieldSetRecords[0].itemRecords, {itemId: itemParent.id},true)[0];
                        var options = $filter('filter')(itemForm.values, {parentOptionId: itemRecordParent.value},true)
                        var selectedValue = $filter('filter')(itemForm.values, function(option){return option.value == itemRecordTemp.value})[0];
                        itemRecordTemp.labelValue = (angular.isDefined(selectedValue) ? selectedValue.label : "(Sin valor)")
                      }else{
                        var selectedValue = $filter('filter')(itemForm.values, function(option){return option.value == itemRecordTemp.value})[0];
                        itemRecordTemp.labelValue = (angular.isDefined(selectedValue) ? selectedValue.label : "(Sin valor)")
                      }
                         
                    loopDeferred.resolve();
                  break;
                  default:
                      loopDeferred.resolve();
                  break;
                }
              }
            })
          })
          $q.all(loopPromises).then(function(){
            deferred.resolve(formRecord);
          });
          return deferred.promise
        }

        var initializeForm = function(formRecord){
          if(formRecord!=undefined){
            angular.forEach(formRecord.fieldSetRecords[0].itemRecords, function(itemRecord){
              angular.forEach(vm.form.fieldSets[0].items, function(item){
                if(itemRecord.itemId == item.id){
                  var type = item.subtype ? item.subtype : item.type;
                  switch(type){
                    case 'date':
                      vm.itemRecords[itemRecord.itemId.toString()] = new Date(itemRecord.value);
                      break;
                    case 'number':
                      vm.itemRecords[itemRecord.itemId.toString()] = parseFloat(itemRecord.value);
                      break;
                    case 'autoincremental':
                      vm.itemRecords[itemRecord.itemId.toString()] = parseFloat(itemRecord.value);
                      break;
                    case 'checkbox':
                      vm.itemRecords[itemRecord.itemId.toString()] = itemRecord.value == "true" ? true: false;
                      break;
                    case 'image':
                    case 'file':
                    case 'drawing':
                      if(itemRecord.hasOwnProperty('documentRecords')){
                        vm.itemRecords[itemRecord.itemId.toString()] = itemRecord.value;
                        for(var i=0; i<itemRecord.documentRecords.length; i++){
                          vm.setFile(dataURLtoFile(itemRecord.documentRecords[i].file, itemRecord.documentRecords[i].fileContentType, itemRecord.documentRecords[i].fileName), itemRecord.itemId, i, itemRecord.documentRecords[i].id)
                        }
                      }
                      break;
                    case 'textarea':
                      if(itemRecord.hasOwnProperty('extraItemRecord')){
                        vm.itemRecords[itemRecord.itemId.toString()] = {extraItemRecord: {longValue: itemRecord.extraItemRecord.longValue}};
                      }else{
                        vm.itemRecords[itemRecord.itemId.toString()] = {extraItemRecord: {longValue: itemRecord.value}};
                      }
                    break;
                    default:
                      vm.itemRecords[itemRecord.itemId.toString()] = itemRecord.value;
                      break;
                  }
                }
              });
            });
          }    
          angular.forEach(vm.form.fieldSets[0].items, function(item,index){
            if(item.visible){
              if(item.visible == "web" || item.visible == "both"){
                if(item.type == "map"){
                  vm.columnsToRenderLeft.unshift(ItemTemplateService.getTemplate(item, vm.form, actionId, formRecords));
                }else{
                  if(vm.form.fieldSets[0].items.length/3<index){
                    vm.columnsToRenderRight.push(ItemTemplateService.getTemplate(item, vm.form, actionId, formRecords));
                  }else{
                    vm.columnsToRenderLeft.push(ItemTemplateService.getTemplate(item, vm.form, actionId, formRecords));
                  }
                }
              }
            }else{
              if(item.type == "map"){
                vm.columnsToRenderLeft.unshift(ItemTemplateService.getTemplate(item, vm.form, actionId, formRecords));
              }else{
                if(vm.form.fieldSets[0].items.length/3<index){
                  vm.columnsToRenderRight.push(ItemTemplateService.getTemplate(item, vm.form, actionId, formRecords));
                }else{
                  vm.columnsToRenderLeft.push(ItemTemplateService.getTemplate(item, vm.form, actionId, formRecords));
                }
              }  
            }
          });
        }
        
        vm.setFile = function(rawFile, itemId, index, id) {
          vm.files[itemId] = vm.files[itemId] == undefined ? [] : vm.files[itemId];
          var file = vm.files[itemId][index] != undefined ? vm.files[itemId][index] : {};
          file.ngFile = rawFile;
          if(id!=undefined){
            file.id = id;
          }
          Upload.base64DataUrl(rawFile).then(function(url){
            file.file = url.split(',')[1];
            file.extension = 'jpg';
            file.fileContentType = rawFile.type;
            file.fileName = rawFile.name;
            vm.files[itemId][index] = file
            vm.itemRecords[itemId] = vm.files[itemId].length;
          })
        }

        vm.submit = function(){
          if (Object.keys(vm.itemRecords).length > 0) {
            $rootScope.showLoading("Actualizando respuestas");
            var loopPromises = [];
            angular.forEach(vm.formRecords, function(formRecord){
              var loopDeferred = $q.defer();
              loopPromises.push(loopDeferred.promise);
              delete formRecord.createdAtOnServer;
              delete formRecord.userName;
              delete formRecord.position;
              delete formRecord.updatedAt;
              angular.forEach(formRecord.fieldSetRecords[0].itemRecords, function(itemRecord, index){
                if(vm.itemRecords.hasOwnProperty(itemRecord.itemId.toString()) && vm.itemRecords[itemRecord.itemId.toString()]!=undefined){
                  if(vm.itemRecords[itemRecord.itemId.toString()] instanceof Date){
                      formRecord.fieldSetRecords[0].itemRecords[index].value = vm.itemRecords[itemRecord.itemId.toString()].toISOString()
                    }else if(vm.itemRecords[itemRecord.itemId.toString()].hasOwnProperty("extraItemRecord") ){
                      if(formRecord.fieldSetRecords[0].itemRecords[index].hasOwnProperty("extraItemRecord")){
                        formRecord.fieldSetRecords[0].itemRecords[index].extraItemRecord.longValue = vm.itemRecords[itemRecord.itemId.toString()].extraItemRecord.longValue
                      }else{
                        formRecord.fieldSetRecords[0].itemRecords[index].value = null;
                        formRecord.fieldSetRecords[0].itemRecords[index].extraItemRecord = vm.itemRecords[itemRecord.itemId.toString()].extraItemRecord
                      }
                    }else{
                      formRecord.fieldSetRecords[0].itemRecords[index].value = vm.itemRecords[itemRecord.itemId.toString()]
                    }
                } 
                delete formRecord.fieldSetRecords[0].itemRecords[index].name;
                delete formRecord.fieldSetRecords[0].itemRecords[index].label;
                delete formRecord.fieldSetRecords[0].itemRecords[index].type; 
                delete formRecord.fieldSetRecords[0].itemRecords[index].documentRecords;
                delete formRecord.fieldSetRecords[0].itemRecords[index].documentRecord; 
                delete formRecord.fieldSetRecords[0].items;
                delete formRecord.createdAtOnServer;
                delete formRecord.userName;
                delete formRecord.position;         
              });
              angular.forEach(vm.itemRecords, function(value, key){
                var notInArray = true;
                for(var i in formRecord.fieldSetRecords[0].itemRecords){
                  if(formRecord.fieldSetRecords[0].itemRecords[i].itemId == key){
                    notInArray = false;
                  }
                }
                if(notInArray){
                  formRecord.fieldSetRecords[0].itemRecords.push({itemId: key, value: value instanceof Date ? value.toISOString(): value })
                }
              })
              var params = actionId == "not_empty_value" ? {} : { manualActionId: vm.actionId, avoidActions: false }
              FormRecord.update(params, formRecord, function(response){
                var secondLoopPromises = [];
                angular.forEach(response.fieldSetRecords[0].itemRecords, function(itemRecord){
                  var secondLoopDeferred = $q.defer();
                  secondLoopPromises.push(secondLoopDeferred.promise);
                  if(vm.files.hasOwnProperty(itemRecord.itemId.toString())){
                    var thirdLoopPromises = [];
                    angular.forEach(vm.files[itemRecord.itemId.toString()], function(file){
                      var thirdLoopDeferred = $q.defer();
                      thirdLoopPromises.push(thirdLoopDeferred.promise);
                      file.itemRecords = [{ id: itemRecord.id }];
                      if(file.id != undefined){
                        DocumentRecord.update({}, file, function(response){
                          thirdLoopDeferred.resolve();
                        });  
                      }else{
                        DocumentRecord.save({}, file, function(response){ 
                          thirdLoopDeferred.resolve();
                        });  
                      }
                    })
                    $q.all(thirdLoopPromises).then(function(){
                      secondLoopDeferred.resolve();
                    }) 
                  }else{
                    secondLoopDeferred.resolve();
                  }
                })
                $q.all(secondLoopPromises).then(function(){
                  loopDeferred.resolve();  
                })
              })
            })
            $q.all(loopPromises).then(function(){
              vm.ok();
            })
          } else {
            vm.ok();
          }
        }

        vm.ok = function () {
          $uibModalInstance.close('ok');
          $rootScope.hideLoading();
        };

        vm.cancel = function () {
          $uibModalInstance.dismiss('canceled');
        };

        vm.resetFormRecord = function(){
          vm.formRecord = {
            fieldSetRecords: [{
              fieldSetId: vm.form.fieldSets[0].id,
              itemRecords: [],
              formId: vm.form.id
            }],
            id: null
          };
          angular.element("input[type='file']").val(null);
          vm.files = {}
        }

        var isFormRecordValid = function(){
          var save = true;
          if (vm.form) {
            angular.forEach(vm.form.fieldSets[0].items, function (item) {
              if(item.modificable){
                var isValid = true;
                var itemRecordValue = vm.itemRecords[item.id.toString()];
                if (item.required && (item.type == "map" || item.type == "image" || item.type == "file" || item.type == "drawing" || item.type == "text" || item.type == "select" || item.type == "date" || item.type == "checkbox"|| item.type == "address")) {
                  if (!itemRecordValue || itemRecordValue == "" || itemRecordValue == []) {
                    if(item.type == "checkbox"){
                      vm.itemRecords[item.id.toString()] = itemRecordValue == undefined ? false : itemRecordValue;
                    }else{
                      isValid = false;  
                    }
                  }
                  if(isValid){
                    delete vm.errors[item.id];
                  }else{ 
                    var adicional = item.subtype == 'number'? " El número debe estar entre "+item.max+" y "+item.min+", y ser multiplo de "+item.step+"." : "";
                    vm.errors[item.id] = '<div class="text-danger"><span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span> El campo '+ item.label+' contiene errores o está vacío.'+adicional+'</div>';
                      save = false;
                  }
                }
              }
            });
          }
          return save;
          }

        function dataURLtoFile(file, fileContentType, filename) {
            var bstr = atob(file), 
                n = bstr.length, 
                u8arr = new Uint8Array(n);
                
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            
            return new File([u8arr], filename, {type:fileContentType});
        }

        if(vm.formRecords.length > 1){
          initializeForm(); 
        }else{
          vm.populateFormRecord(vm.formRecords[0]).then(function(formRecord){
            initializeForm(formRecord);
          })
        }

    }

})();