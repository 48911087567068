(function() {
    'use strict';

    angular
        .module('pickerApp')
        .controller('ModalNewFormRecordCtrl', ModalNewFormRecordCtrl);

    ModalNewFormRecordCtrl.$inject = [
      '$uibModalInstance',
      '$rootScope',
      '$scope',
      'Principal',
      'LoginService',
      'ItemTemplateService',
      'FormRecord',
      'Form',
      'ItemRecord',
      'DocumentRecord',
      '$state',
      '$filter',
      'ModalHelper',
      'form',
      '$q',
      'formRecordId',
      'newFormRecord',
      'permissions',
      '$timeout',
      '$compile',
      'gRecaptcha',
      'PublicService',
      'FileHelper'
    ];

    function ModalNewFormRecordCtrl (
      $uibModalInstance,
      $rootScope,
      $scope,
      Principal,
      LoginService,
      ItemTemplateService,
      FormRecord,
      Form,
      ItemRecord,
      DocumentRecord,
      $state,
      $filter,
      ModalHelper,
      form,
      $q,
      formRecordId,
      newFormRecord,
      permissions,
      $timeout,
      $compile,
      gRecaptcha,
      PublicService,
      FileHelper
    ) {
        if (!$uibModalInstance) {
          gRecaptcha.initialize({key: '6Ldq3_0gAAAAAKTRp6UYUbM4e_qxIKaMpgsbRaP6'});
        }
        var vm = this;
        vm.form = form;
        vm.columnsToRenderRight = [];
        vm.columnsToRenderLeft = [];
        vm.itemRecords = {};
        vm.files = {};
        vm.permissions = permissions;
        vm.organization = $rootScope.organization;
        vm.thereAreErrosOnFiles = false;

        vm.populateFormRecordFromEntity = function(formRecord){
          var deferred = $q.defer();
          var loopPromises = [];
          var itemRecords = [];
          angular.forEach(formRecord.fieldSetRecords, function(fieldSetRecord){
            angular.forEach(fieldSetRecord.itemRecords, function(itemRecord){
              itemRecords.push(itemRecord);
            })
          })
          angular.forEach(vm.form.fieldSets, function(fieldSet){
            angular.forEach(fieldSet.items, function(itemForm){
              var itemRecordTemp = $filter('filter')(itemRecords, {itemId: itemForm.id})[0];
              if(angular.isDefined(itemRecordTemp)){
                var loopDeferred = $q.defer();
                loopPromises.push(loopDeferred.promise);
                //itemRecordTemp.name = itemForm.name;
                //itemRecordTemp.label = itemForm.label;
                itemRecordTemp.type = itemForm.type;
                switch(itemRecordTemp.type){
                  case 'map':
                    if (itemRecordTemp.value && itemRecordTemp.value.indexOf("|") > -1) {
                      var splitValues = itemRecordTemp.value.split('|');
                      itemRecordTemp.markers = {
                        currentPosition: {
                          lat: parseFloat(splitValues[0]),
                          lng: parseFloat(splitValues[1]),
                          focus: false,
                          draggable: false
                        }
                      }
                      itemRecordTemp.center = {
                        lat: parseFloat(splitValues[0]),
                        lng: parseFloat(splitValues[1]),
                        zoom: 17
                      }  
                    }
                    loopDeferred.resolve();
                  break;
                  case 'image':
                  case 'drawing':
                  case 'file':
                    var secondLoopPromises = []
                    ItemRecord.getDocumentRecords({id: itemRecordTemp.id}).$promise.then(function(response){
                      itemRecordTemp.documentRecords = [];
                      if(response.length == 0){
                          loopDeferred.resolve();
                      }else{
                        angular.forEach(response, function(documentRecord){
                          var secondLoopDeferred = $q.defer();
                          secondLoopPromises.push(secondLoopDeferred.promise);
                          DocumentRecord.get({id: documentRecord.id}).$promise.then(function(response){
                            $timeout(function() {
                              itemRecordTemp.documentRecords.push(response)
                              secondLoopDeferred.resolve();
                            }, 100);
                          }, function(error){
                            console.log(error);
                            secondLoopDeferred.resolve()
                          })
                        })
                        $q.all(secondLoopPromises).then(function(){
                          loopDeferred.resolve();
                        })  
                      }
                    }, function(error){
                      console.log(error);
                      loopDeferred.resolve();
                    })
                  break;
                  case 'select':
                    if(itemForm.parentItemId){
                      var itemParent = $filter('filter')(vm.form.fieldSets[0].items, {name: itemForm.parentItemId}, true)[0];
                      var itemRecordParent = $filter('filter')(formRecord.fieldSetRecords[0].itemRecords, {itemId: itemParent.id},true)[0];
                      if(itemRecordParent && itemRecordParent.value){
                        var options = $filter('filter')(itemForm.values, {parentOptionId: itemRecordParent.value},true)
                        var selectedValue = $filter('filter')(itemForm.values, function(option){return option.value == itemRecordTemp.value})[0];
                        itemRecordTemp.labelValue = (angular.isDefined(selectedValue) ? selectedValue.label : "(Sin valor)")  
                      }else{
                        itemRecordTemp.labelValue = "(Sin valor)";
                      }
                    }else{
                      var selectedValue = $filter('filter')(itemForm.values, function(option){return option.value == itemRecordTemp.value})[0];
                      itemRecordTemp.labelValue = (angular.isDefined(selectedValue) ? selectedValue.label : "(Sin valor)")
                    }    
                    loopDeferred.resolve();
                  break;
                  default:
                      loopDeferred.resolve();
                  break;
                }
              }
            });
          });
          $q.all(loopPromises).then(function(){
            deferred.resolve(formRecord);
          });
          return deferred.promise;
        }

        vm.getAndPopulateFormRecord = function(formRecordId){
          var deferred = $q.defer();
          var loopPromises = [];
          FormRecord.get({id: formRecordId}).$promise.then(function(response){
            vm.populateFormRecordFromEntity(response).then(function(result){
              deferred.resolve(result);
            })
          });
          return deferred.promise
        }

        var initializeForm = function(){
          vm.errors = {};
          vm.formRecord = {
            fieldSetRecords: [{
              fieldSetId: vm.form.fieldSets[0].id,
              itemRecords: []
            }],
            id: formRecord ? formRecord.id : null,
            formId: vm.form.id
          };    
          var getValueFromLastFormRecord = false;
          var getAllValuesFromLastFormRecord = false;
          getAllValuesFromLastFormRecord = vm.form.properties.vluesFromLastFormRecord || getAllValuesFromLastFormRecord;
          
          angular.forEach(vm.form.fieldSets[0].items, function(item){
              getValueFromLastFormRecord = item.valueFromLastFormRecord == "true" || getValueFromLastFormRecord;
          })

          if(formRecord!=undefined){
            vm.formRecord.timestampAction = formRecord.timestampAction ? formRecord.timestampAction.toISOString() : undefined;
            vm.formRecord.createdById = formRecord.createdById ? formRecord.createdById : undefined;
            vm.formRecord.createdAt = formRecord.createdAt;
            vm.formRecord.relatedFormRecordId = formRecord.relatedFormRecordId ? formRecord.relatedFormRecordId : undefined; 
            vm.isRemovable = formRecord.id ? true : false;
            angular.forEach(formRecord.fieldSetRecords[0].itemRecords, function(itemRecord){
              angular.forEach(vm.form.fieldSets[0].items, function(item){
                if(itemRecord.itemId == item.id){
                  assignValueFromItemRecord(item, itemRecord)
                }
              });
            });
            organiceItemsEditFormRecord();
          }else{
            if(getValueFromLastFormRecord || getAllValuesFromLastFormRecord){
              FormRecord.getLast({formId: vm.form.id}, function(response){
                if(response.id){
                  vm.populateFormRecordFromEntity(response).then(function(result){
                    var formRecord = result;
                    angular.forEach(formRecord.fieldSetRecords[0].itemRecords, function(itemRecord){
                      angular.forEach(vm.form.fieldSets[0].items, function(item){
                        if(itemRecord.itemId == item.id && (item.valueFromLastFormRecord == "true" || getAllValuesFromLastFormRecord)) {
                          assignValueFromItemRecord(item, itemRecord);
                        }else if(itemRecord.itemId == item.id){
                          assignValueFromDefault(item);
                        }
                      });
                    });
                    organiceItemsNewFormRecord();
                  });  
                }else{
                  angular.forEach(vm.form.fieldSets[0].items, function(item){
                    assignValueFromDefault(item);
                  })
                  organiceItemsNewFormRecord()
                }
              })
            }else{
              angular.forEach(vm.form.fieldSets[0].items, function(item){
                assignValueFromDefault(item);
              })
              organiceItemsNewFormRecord()
            } 
          } 
        }

        function assignValueFromItemRecord(item, itemRecord){
          var type = item.subtype ? item.subtype : item.type;
          switch(type){
            case 'date':
              vm.itemRecords[itemRecord.itemId.toString()] = itemRecord.value ? new Date(itemRecord.value) : null;
              break;
            case 'autoincremental':
              vm.itemRecords[itemRecord.itemId.toString()] = parseFloat(itemRecord.value);
              break;
            case 'number':
              vm.itemRecords[itemRecord.itemId.toString()] = parseFloat(itemRecord.value);
              break;
            case 'checkbox':
              vm.itemRecords[itemRecord.itemId.toString()] = itemRecord.value == "true" ? true: false;
              break;
            case 'image':
            case 'drawing':
            case 'file':
              if(itemRecord.hasOwnProperty('documentRecords')){
                vm.itemRecords[itemRecord.itemId.toString()] = itemRecord.value;
                for(var i=0; i<itemRecord.documentRecords.length; i++){
                  FileHelper.setFile(
                    dataURLtoFile(itemRecord.documentRecords[i].file, itemRecord.documentRecords[i].fileContentType, itemRecord.documentRecords[i].fileName),
                    itemRecord.itemId,
                    i,
                    itemRecord.documentRecords[i].id,
                    vm.files
                  );
                }
              }
              break;
            case 'textarea':
                if(itemRecord.hasOwnProperty('extraItemRecord')){
                  vm.itemRecords[itemRecord.itemId.toString()] = {extraItemRecord: {longValue: itemRecord.extraItemRecord.longValue}};
                }else{
                  vm.itemRecords[itemRecord.itemId.toString()] = {extraItemRecord: {longValue: itemRecord.value}};
                }
              break;
            default:
              vm.itemRecords[itemRecord.itemId.toString()] = itemRecord.value;
              break;
          }
        }

        function assignValueFromDefault(item){
          var type = item.subtype ? item.subtype : item.type;
          switch(type){
            case 'date':
              if(item.value){
                vm.itemRecords[item.id.toString()] = item.value ? new Date(item.value) : null;  
              }
              break;
            case 'number':
              if(item.value){
                vm.itemRecords[item.id.toString()] = parseFloat(item.value);
              }
              break;
            case 'text':
              if(item.value){
                vm.itemRecords[item.id.toString()] = item.value;
              }
              break;
            case 'address':
              if(item.value){
                vm.itemRecords[item.id.toString()] = item.value;
              }
              break;
            case 'textarea':
              if(item.value){
                vm.itemRecords[item.id.toString()] = { extraItemRecord: {longValue: item.value} };
              }
              break;
            case 'checkbox':
              if(item.value){
                vm.itemRecords[item.id.toString()] = item.value == "true" ? true: false;  
              }
              break;
            case 'select':
              angular.forEach(item.values, function(value){
                if(value.selected){
                  vm.itemRecords[item.id.toString()] = value.value;    
                }
              })
              break;
          }
        }

        function organiceItemsNewFormRecord(){
          angular.forEach(vm.form.fieldSets[0].items, function(item,index){
            if(item.visible){
              if(item.visible == "web" || item.visible == "both" || item.visible == "print"){
                pushItemInColumns(item, index);
              }
            }else{
              pushItemInColumns(item, index);
            }
          }); 
        }

        function organiceItemsEditFormRecord(){
          angular.forEach(vm.form.fieldSets[0].items, function(item,index){
            if(item.visible){
              if(item.visible == "web" || item.visible == "both" || item.visible == "print"){
                if(item.editable){
                  if(item.editable == "web" || item.editable == "both"){
                    pushItemInColumns(item, index);
                  }
                }else{
                  pushItemInColumns(item, index);
                }
              }
            }else if(item.editable){
              if(item.editable == "web" || item.editable == "both"){
                pushItemInColumns(item, index);
              }
            }else{
              pushItemInColumns(item, index);
            }
          });
        }

        function pushItemInColumns(item, index){
          if(item.type == "map"){
            vm.columnsToRenderLeft.unshift(ItemTemplateService.getTemplate(item, vm.form));
          }else{
            if(vm.form.fieldSets[0].items.length/3<index){
              vm.columnsToRenderRight.push(ItemTemplateService.getTemplate(item, vm.form));
            }else{
              vm.columnsToRenderLeft.push(ItemTemplateService.getTemplate(item, vm.form));
            }
          }  
        }

        vm.submit = function(){
          if(isFormRecordValid()){
            /*if (!$uibModalInstance) {
              $rootScope.showLoading('Validando ReCaptcha...')
              gRecaptcha.execute({action: 'verify'})
                .then(function (token) {
                  PublicService.reCaptchaVerify({}, {token: token}, function(response) {
                    $rootScope.hideLoading();
                    verifiedSubmit();
                  },function(error) { 
                    $rootScope.hideLoading();
                    ModalHelper.error("Error de validación ReCaptcha");
                  })
              })
            } else {
              verifiedSubmit();
            }*/
            verifiedSubmit();
          }
        }

        function verifiedSubmit() {
          var number;
          var item;
          $rootScope.showLoading("Guardando...")
          if(formRecord && formRecord.id){  
            var formRecordCopy = angular.copy(formRecord);
            delete formRecordCopy.createdAtOnServer;
            delete formRecordCopy.position;
            delete formRecordCopy.updatedAt;
            delete formRecordCopy.fieldSetRecords[0].items;
            delete formRecordCopy.userName;
            angular.forEach(formRecordCopy.fieldSetRecords[0].itemRecords, function(itemRecord, index){
              if(vm.itemRecords.hasOwnProperty(itemRecord.itemId.toString()) && vm.itemRecords[itemRecord.itemId.toString()]!=undefined){
                if(vm.itemRecords[itemRecord.itemId.toString()] instanceof Date){
                  formRecordCopy.fieldSetRecords[0].itemRecords[index].value = vm.itemRecords[itemRecord.itemId.toString()].toISOString()
                }else if( vm.itemRecords[itemRecord.itemId.toString()].hasOwnProperty("extraItemRecord") ){
                  if(formRecordCopy.fieldSetRecords[0].itemRecords[index].hasOwnProperty("extraItemRecord")){
                    formRecordCopy.fieldSetRecords[0].itemRecords[index].extraItemRecord.longValue = vm.itemRecords[itemRecord.itemId.toString()].extraItemRecord.longValue;
                  }else{
                    formRecordCopy.fieldSetRecords[0].itemRecords[index].value = null;
                    formRecordCopy.fieldSetRecords[0].itemRecords[index].extraItemRecord = vm.itemRecords[itemRecord.itemId.toString()].extraItemRecord
                  }
                }else{
                  formRecordCopy.fieldSetRecords[0].itemRecords[index].value = vm.itemRecords[itemRecord.itemId.toString()]
                }
              }
              delete itemRecord.documentRecords;
              delete itemRecord.documentsRecords;
              delete itemRecord.type;         
            });
            angular.forEach(vm.itemRecords, function(value, key){
              var notInArray = true;
              for(var i in formRecordCopy.fieldSetRecords[0].itemRecords){
                if(formRecordCopy.fieldSetRecords[0].itemRecords[i].itemId == key){
                  notInArray = false;
                }
              }
              if(notInArray){
                if (value.hasOwnProperty('extraItemRecord')) {
                  formRecordCopy.fieldSetRecords[0].itemRecords.push({itemId: key, value: null, extraItemRecord: value.extraItemRecord });
                } else {
                  formRecordCopy.fieldSetRecords[0].itemRecords.push({itemId: key, value: value instanceof Date ? value.toISOString(): value })
                }
              }
            })
            FormRecord.update({}, formRecordCopy, function(response){
              var deferred = $q.defer();
              var loopPromises = [];
              loopPromises.push(deferred.promise);
              angular.forEach(response.fieldSetRecords[0].itemRecords, function(itemRecord){
                if(vm.files.hasOwnProperty(itemRecord.itemId.toString())){
                  $rootScope.updateTextLoading('Actualizando archivos...');
                  angular.forEach(vm.files[itemRecord.itemId.toString()], function(file){
                    file.itemRecords = [{ id: itemRecord.id }];
                    var loopDeferred = $q.defer();
                    loopPromises.push(loopDeferred.promise);
                    if(file.id != undefined){
                      if (file.toDelete) {
                        ItemRecord.deleteDocumentRecord({ id: itemRecord.id, idDocument: file.id}, {}, function(response){
                          deferred.resolve()
                          loopDeferred.resolve()
                        }, function(error) {
                          vm.thereAreErrosOnFiles = true;
                          deferred.resolve()
                          loopDeferred.resolve()
                        });
                      } else {
                        DocumentRecord.update({}, file, function(response){
                          deferred.resolve()
                          loopDeferred.resolve()
                        }, function(error) {
                          vm.thereAreErrosOnFiles = true;
                          deferred.resolve()
                          loopDeferred.resolve()
                        });  
                      }
                    }else{
                      DocumentRecord.save({}, file, function(response){
                        deferred.resolve()
                        loopDeferred.resolve()
                      }, function(error) {
                        vm.thereAreErrosOnFiles = true;
                        deferred.resolve()
                        loopDeferred.resolve()
                      });  
                    }
                  }) 
                } else {
                  deferred.resolve();
                }
              })
              angular.forEach(vm.form.fieldSets[0].items, function(_item){
                if(_item.type == "autoincremental"){
                  item = _item;
                }
              })
              if(item){
                angular.forEach(response.fieldSetRecords[0].itemRecords, function(itemRecord){
                  if(itemRecord.itemId == item.id){
                    number = itemRecord.value; 
                  }
                }) 
              }
              $q.all(loopPromises).then(function() {
                vm.resetFormRecord();
                vm.ok(item, number);
              })
            });
          }else{      
            angular.forEach(vm.form.fieldSets[0].items, function(item){
              if(vm.itemRecords.hasOwnProperty(item.id.toString()) && vm.itemRecords[item.id.toString()]!=undefined){
                  if(vm.itemRecords[item.id.toString()] instanceof Date){
                    vm.formRecord.fieldSetRecords[0].itemRecords.push({itemId: item.id, value: vm.itemRecords[item.id.toString()].toISOString()})
                  }else if( vm.itemRecords[item.id.toString()].hasOwnProperty("extraItemRecord") ){
                    vm.formRecord.fieldSetRecords[0].itemRecords.push({itemId: item.id, extraItemRecord: vm.itemRecords[item.id.toString()].extraItemRecord})
                  }else{
                    vm.formRecord.fieldSetRecords[0].itemRecords.push({itemId: item.id, value: vm.itemRecords[item.id.toString()]})
                  }
                    
              }else{
                vm.formRecord.fieldSetRecords[0].itemRecords.push({itemId: item.id});
              }
            });
            vm.formRecord.createdAt = formRecord ? formRecord.createdAt : (new Date()).toISOString();
            if ($uibModalInstance) {
              FormRecord.save({},vm.formRecord, function(response) {
                afterSave(response, item, number);
              });
            } else {
              PublicService.saveFormRecord({},vm.formRecord, function(response) {
                afterSave(response, item, number);
              });
            }

          }
        }

        function afterSave(response, item, number) {
          var deferred = $q.defer();
          var loopPromises = [];
          loopPromises.push(deferred.promise);
          if (Object.keys(vm.files).length > 0) {
            angular.forEach(response.fieldSetRecords[0].itemRecords, function(itemRecord){
              if(vm.files.hasOwnProperty(itemRecord.itemId.toString())){
                angular.forEach(vm.files[itemRecord.itemId.toString()], function(file, index){
                  file.itemRecords = [{ id: itemRecord.id }];
                  var loopDeferred = $q.defer();
                  loopPromises.push(loopDeferred.promise)
                  $rootScope.updateTextLoading('Guardando archivos...');
                  DocumentRecord.save({}, file, function(response) {
                    deferred.resolve()
                    loopDeferred.resolve()
                  }, function(error) {
                    vm.thereAreErrosOnFiles = true;
                    deferred.resolve()
                    loopDeferred.resolve()
                  });  
                }) 
              }
            })
          } else {
            deferred.resolve();
          }
          angular.forEach(vm.form.fieldSets[0].items, function(_item){
            if(_item.type == "autoincremental"){
              item = _item;
            }
          })
          if(item){
            angular.forEach(response.fieldSetRecords[0].itemRecords, function(itemRecord){
              if(itemRecord.itemId == item.id){
                number = itemRecord.value; 
              }
            }) 
          }
          $q.all(loopPromises).then(function() {
            vm.resetFormRecord();
            vm.ok(item, number);
          })
        }

        vm.ok = function (item, number) {
          $rootScope.hideLoading();
          var msg = "La respuesta fue guardada con éxito"
          if(number){
            msg = "La respuesta  <strong>"+item.label+": "+number+"</strong> fue guardada con éxito";
          }
          if (vm.thereAreErrosOnFiles) {
            msg = msg + "<br>Sin embargo, una o más archivos no pudieron ser guardados por errores en la conexión.<br>Por favor re intente nuevamente editando su respuesta."
          }
          var modalInstance = ModalHelper.alert(msg);

          modalInstance.result.then(function(value){
            vm.thereAreErrosOnFiles = false;
            if ($uibModalInstance) {
              $uibModalInstance.close('ok');
            } else {
              window.location.reload();
            }
          }, function(error){

          })
        };

        vm.cancel = function () {
          if ($uibModalInstance) {                
            $uibModalInstance.dismiss('canceled');
          } else {
            window.location.reload();
          }
        };

        vm.delete = function(){
            var modalInstance = ModalHelper.confirmation('¿Está seguro de eliminar la respuesta?');

          modalInstance.result.then(function (confirmation) {
            if(confirmation){
              FormRecord.delete({id: vm.formRecord.id}, {},function(response){
                if ($uibModalInstance) {                
                  $uibModalInstance.close('deleted');
                } else {
                  window.location.reload();
                }
              });
            }
          }, function () {
            
          });
          
          
        };

        vm.track = function(){
          var modalInstance = ModalHelper.open(
            'app/entities/form-record/modal-form-record-track.html',
            'ModalFormRecordTrackCtrl',{
              formRecord: function () {
                return formRecord;
              },
              permissions: function(){
                return vm.permissions;
              }
            }
          );

          vm.activePrintClasses = true;
          modalInstance.result.then(function () {        
            vm.activePrintClasses = false;
          }, function () {
            
          });
        };


      window.addEventListener("afterprint", function(event) {
        vm.printing = false;
        $scope.$apply();
      });

      vm.print = function() {
        vm.printing = true;
        $scope.$$postDigest(function () {
          window.print();
        })
      }

      vm.resetFormRecord = function() {
        vm.formRecord = {
          fieldSetRecords: [{
            fieldSetId: vm.form.fieldSets[0].id,
            itemRecords: [],
            formId: vm.form.id
          }],
          id: null
        };
        angular.element("input[type='file']").val(null);
        vm.files = {};
      }

      var isFormRecordValid = function(){
        var dontSave = true;
        if(vm.form) {
          angular.forEach(vm.form.fieldSets[0].items, function (item) {
            var isValid = true;
            var itemRecordValue = vm.itemRecords[item.id.toString()];
            if (item.required && (item.type == "map" || item.type == "image" || item.type == "drawing" || item.type == "file" || item.type == "text" || item.type == "select" || item.type == "date" || item.type == "checkbox" || item.type == "address" || item.type == "textarea")) {
              if(item.type == "textarea"){
                if(!itemRecordValue || !itemRecordValue.extraItemRecord || itemRecordValue.extraItemRecord.longValue == "" || itemRecordValue.extraItemRecord.longValue == []){
                  isValid = false;
                }
              }else{
                if (!itemRecordValue || itemRecordValue == "" || itemRecordValue == []) {
                  if(item.type == "checkbox"){
                    vm.itemRecords[item.id.toString()] = itemRecordValue == undefined ? false : itemRecordValue;
                  }else{
                    isValid = false;  
                  }
                }  
              }
              if(isValid){
                delete vm.errors[item.id];
              }else{ 
                var adicional = item.subtype == 'number'? " El número debe estar entre "+item.max+" y "+item.min+", y ser multiplo de "+item.step+"." : "";
                vm.errors[item.id] = '<div class="text-danger"><span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span> El campo '+ item.label+' contiene errores o está vacío.'+adicional+'</div>';
                  dontSave = false;
              }
            }
          });
        }
        return dontSave;
      }

      function dataURLtoFile(file, fileContentType, filename) {
          var bstr = atob(file), 
              n = bstr.length, 
              u8arr = new Uint8Array(n);
              
          while(n--){
              u8arr[n] = bstr.charCodeAt(n);
          }
          
          return new File([u8arr], filename, {type:fileContentType});
      }

      var formRecord = newFormRecord;
      if(formRecordId != undefined){
        vm.getAndPopulateFormRecord(formRecordId).then(function(response){
          formRecord = response;
          initializeForm();
        }) 
      }else{
        initializeForm();
      }
    }
})();

(function(){
  'use strict';
  angular.module('pickerApp')
  .directive('htmlContent', ['$compile', function ($compile) {
    return {
      scope: {
        htmlContent: "="
      },
      link: function(scope, element, attrs) {
        scope.$watch("htmlContent", function(newVal){
          var compiledContent = $compile(scope.htmlContent)(scope.$parent);
          element.html(compiledContent);
        }, true)
      }
    };
}])
})();



(function(){
  'use strict';
  angular.module('pickerApp')
  .directive('htmlContentParent', ['$compile', function ($compile) {
    return {
      scope: {
        htmlContentParent: "="
      },
      link: function(scope, element, attrs) {
        scope.$watch("htmlContent", function(newVal){
          var compiledContent = $compile(scope.htmlContentParent)(scope.$parent.$parent);
          element.html(compiledContent);
        }, true)
      }
    };
}])
})();