(function() {
    'use strict';

    angular
        .module('pickerApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ngSanitize',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'nemLogging',
            'ngMap',
            'colorpicker.module',
            'angular-sortable-view',
            'chart.js',
            'fayzaan.gRecaptcha.v3'
        ])
        .run(run);

    run.$inject = ['stateHandler', 'translationHandler', '$rootScope', '$uibModal', '$timeout', 'Organization', 'Principal', 'ModalHelper'];

    function run(stateHandler, translationHandler, $rootScope, $uibModal, $timeout, Organization, Principal, ModalHelper) {
        stateHandler.initialize();
        translationHandler.initialize();

        $rootScope.themes = {
            default: {
                backgroundSideBarLeft: "#2a3f54",
                backgroundBorderSideBarLeft: "#1ABB9C",
                backgroundActiveParentSideBarLeft: "#2a3f54",
                colorActiveParentSideBarLeft: "#E7E7E7",
                colorHoverElementSideBarLeft: "#F2F5F7",
                backgroundPanelHeaderRight: "#2a3f54",
                colorChildElementSideBarLeft: "#E7E7E7",
                backgroundDotSideBarLeft: "#425668",
                backgroundFooterSideBarLeft: "#172D44",
                colorElementsFooterSideBarLeft: "#5A738E",
                backgroundHoverElementsFooterSideBarLeft: "#425567",
                btnTheme: "#425567",
                colorBtnTheme: "#E7E7E7",
                hoverBtnTheme: "#172D44",
                multi: "#73879C",
                multiHover: "#23527c",
                badgeFiltersList: "#2a3f54",
                iconsFiltersList: "#2a3f54",
                listBoldLineHeader: "#000000",
                pageName: "#2a3f54",
                hoverPageName: "#23527c",
                loadingModal: "#2a3f54",
                bodyBackground: "#F7F7F7",
                bodyColor: "#73879C",
                tableOdd: "#f9f9f9",
                tableRowBorder: "#dddddd",
                panelRightBackground: "#FFFFFF",
                panelRightBorder: "#dddddd"
            },
            bright: {
                backgroundSideBarLeft: "#ffffff",
                backgroundBorderSideBarLeft: "#f28e8a",
                backgroundActiveParentSideBarLeft: "#ed2400",
                colorActiveParentSideBarLeft: "#ffffff",
                colorHoverElementSideBarLeft: "#ef6f68",
                backgroundPanelHeaderRight: "#ed2400",
                colorChildElementSideBarLeft: "#ed2400",
                backgroundDotSideBarLeft: "#f28e8a",
                backgroundFooterSideBarLeft: "#ef6f68",
                colorElementsFooterSideBarLeft: "#ffffff",
                backgroundHoverElementsFooterSideBarLeft: "#ed2400",
                btnTheme: "#ef6f68",
                colorBtnTheme: "#ffffff",
                hoverBtnTheme: "#ed2400",                
                multi: "#ed2400", /*inactive tab, table info, table pagination, select options, labels, list data, list header*/
                multiHover: "#ef6f68",
                badgeFiltersList: "#ef6f68",
                iconsFiltersList: "#ed2400",
                listBoldLineHeader: "#ed2400",
                pageName: "#ed2400",
                hoverPageName: "#ef6f68",
                loadingModal: "#ed2400",
                bodyBackground: "#F7F7F7",
                bodyColor: "#ed2400",
                tableOdd: "#f9f9f9",
                tableRowBorder: "#dddddd",
                panelRightBackground: "#FFFFFF",
                panelRightBorder: "#dddddd"
            },
            dark: {
                backgroundSideBarLeft: "#2b283f",
                backgroundBorderSideBarLeft: "#ea436e",
                backgroundActiveParentSideBarLeft: "#37334d",
                colorActiveParentSideBarLeft: "#ffffff",
                colorHoverElementSideBarLeft: "#ba2313",
                backgroundPanelHeaderRight: "#423d5f",
                colorChildElementSideBarLeft: "#ffffff",
                backgroundDotSideBarLeft: "#63d1fa",
                backgroundFooterSideBarLeft: "#423d5f",
                colorElementsFooterSideBarLeft: "#ea436e",
                backgroundHoverElementsFooterSideBarLeft: "#5e5686",
                btnTheme: "#e6406f",
                colorBtnTheme: "#ffffff",
                hoverBtnTheme: "#c0355c",
                multi: "#ffffff", /*inactive tab, table info, table pagination, select options, labels, list data, list header*/
                multiHover: "#ba2313",                
                badgeFiltersList: "#6ae2fd",                                
                iconsFiltersList: "#6ae2fd",
                listBoldLineHeader: "#ea436e",
                pageName: "#f07790",
                hoverPageName: "#ba2313",
                loadingModal: "#111b36",
                bodyBackground: "#423d5f",
                bodyColor: "#ffffff",
                tableOdd: "#514d6b",
                tableRowBorder: "#559cb1",
                panelRightBackground: "#2b283f",
                panelRightBorder: "#2b283f"
            },
            green: {
                backgroundSideBarLeft: "#44ad69",
                backgroundBorderSideBarLeft: "#d01e10",
                backgroundActiveParentSideBarLeft: "#3a975b",
                colorActiveParentSideBarLeft: "#ffffff",
                colorHoverElementSideBarLeft: "#5af08f",
                backgroundPanelHeaderRight: "#3a975b",
                colorChildElementSideBarLeft: "#ffffff",
                backgroundDotSideBarLeft: "#3a975b",
                backgroundFooterSideBarLeft: "#3a975b",
                colorElementsFooterSideBarLeft: "#ffffff",
                backgroundHoverElementsFooterSideBarLeft: "#ac190d",
                btnTheme: "#d01e10",
                colorBtnTheme: "#ffffff",
                hoverBtnTheme: "#ac190d",
                multi: "#020202", /*inactive tab, table info, table pagination, select options, labels, list data, list header*/
                multiHover: "#ac190d",
                badgeFiltersList: "#6180ff",                
                iconsFiltersList: "#6180ff",
                listBoldLineHeader: "#6180ff",
                pageName: "#3a975b",
                hoverPageName: "#4bc476",
                loadingModal: "#3a975b",
                bodyBackground: "#F7F7F7",
                bodyColor: "#020202",
                tableOdd: "#f9f9f9",
                tableRowBorder: "#dddddd",
                panelRightBackground: "#FFFFFF",
                panelRightBorder: "#dddddd"
            },
            hilfiger: {
                backgroundSideBarLeft: "#111b36",
                backgroundBorderSideBarLeft: "#ba2313",
                backgroundActiveParentSideBarLeft: "#ba2313",
                colorActiveParentSideBarLeft: "#ffffff",
                colorHoverElementSideBarLeft: "#ba2313",
                backgroundPanelHeaderRight: "#ba2313",
                colorChildElementSideBarLeft: "#ffffff",
                backgroundDotSideBarLeft: "#ba2313",
                backgroundFooterSideBarLeft: "#b8240e",
                colorElementsFooterSideBarLeft: "#ffffff",
                backgroundHoverElementsFooterSideBarLeft: "#c92514",
                btnTheme: "#111b36",
                colorBtnTheme: "#ffffff",
                hoverBtnTheme: "#ba2313",
                multi: "#111b36", /*inactive tab, table info, table pagination, select options, labels, list data, list header*/
                multiHover: "#ba2313",                
                badgeFiltersList: "#111b36",
                iconsFiltersList: "#111b36",
                listBoldLineHeader: "#b8240e",
                pageName: "#111b36",
                hoverPageName: "#ba2313",
                loadingModal: "#111b36",
                bodyBackground: "#F7F7F7",
                bodyColor: "#111b36",
                tableOdd: "#f9f9f9",
                tableRowBorder: "#dddddd",
                panelRightBackground: "#FFFFFF",
                panelRightBorder: "#dddddd"
            }
        }
        
        $rootScope.setTheme = function(){
            Principal.identity().then(function(user){
                if(user && user.organizationId){
                    $rootScope.theme = $rootScope.themes[$rootScope.organization.theme]
                    if(!$rootScope.theme){
                        $rootScope.theme = $rootScope.themes["default"];
                    }
                    document.documentElement.style.setProperty("--backgroundSideBarLeft",$rootScope.theme.backgroundSideBarLeft);
                    document.documentElement.style.setProperty("--backgroundBorderSideBarLeft",$rootScope.theme.backgroundBorderSideBarLeft);
                    document.documentElement.style.setProperty("--backgroundActiveParentSideBarLeft",$rootScope.theme.backgroundActiveParentSideBarLeft);
                    document.documentElement.style.setProperty("--colorActiveParentSideBarLeft",$rootScope.theme.colorActiveParentSideBarLeft);
                    document.documentElement.style.setProperty("--colorHoverElementSideBarLeft",$rootScope.theme.colorHoverElementSideBarLeft);
                    document.documentElement.style.setProperty("--backgroundPanelHeaderRight",$rootScope.theme.backgroundPanelHeaderRight);
                    document.documentElement.style.setProperty("--colorChildElementSideBarLeft",$rootScope.theme.colorChildElementSideBarLeft);
                    document.documentElement.style.setProperty("--backgroundDotSideBarLeft",$rootScope.theme.backgroundDotSideBarLeft);
                    document.documentElement.style.setProperty("--backgroundFooterSideBarLeft",$rootScope.theme.backgroundFooterSideBarLeft);
                    document.documentElement.style.setProperty("--colorElementsFooterSideBarLeft",$rootScope.theme.colorElementsFooterSideBarLeft);

                    document.documentElement.style.setProperty("--backgroundHoverElementsFooterSideBarLeft",$rootScope.theme.backgroundHoverElementsFooterSideBarLeft);
                    document.documentElement.style.setProperty("--btnTheme",$rootScope.theme.btnTheme);
                    document.documentElement.style.setProperty("--colorBtnTheme",$rootScope.theme.colorBtnTheme);
                    document.documentElement.style.setProperty("--hoverBtnTheme",$rootScope.theme.hoverBtnTheme);
                    document.documentElement.style.setProperty("--multi",$rootScope.theme.multi);
                    document.documentElement.style.setProperty("--multiHover",$rootScope.theme.multiHover);                        
                    document.documentElement.style.setProperty("--badgeFiltersList",$rootScope.theme.badgeFiltersList);
                    document.documentElement.style.setProperty("--iconsFiltersList",$rootScope.theme.iconsFiltersList);
                    document.documentElement.style.setProperty("--listBoldLineHeader",$rootScope.theme.listBoldLineHeader);
                    document.documentElement.style.setProperty("--pageName",$rootScope.theme.pageName);
                    document.documentElement.style.setProperty("--hoverPageName",$rootScope.theme.hoverPageName);                      
                    document.documentElement.style.setProperty("--loadingModal",$rootScope.theme.loadingModal);
                    document.documentElement.style.setProperty("--bodyColor",$rootScope.theme.bodyColor);            
                    document.documentElement.style.setProperty("--bodyBackground",$rootScope.theme.bodyBackground);
                    document.documentElement.style.setProperty("--tableOdd",$rootScope.theme.tableOdd);
                    document.documentElement.style.setProperty("--tableRowBorder",$rootScope.theme.tableRowBorder);
                    document.documentElement.style.setProperty("--panelRightBackground",$rootScope.theme.panelRightBackground);
                    document.documentElement.style.setProperty("--panelRightBorder",$rootScope.theme.panelRightBorder);    
                }else{
                    if(!$rootScope.theme){
                        $rootScope.theme = $rootScope.themes["default"];
                    }
                }            
            })
        }

        $rootScope.setTheme();

        var loadingsModals = [];

        $rootScope.showLoading = function(text){
            $rootScope.loadingText = text;
            loadingsModals.push($uibModal.open({
                template: '<div><span ng-bind-html="loadingText"></span><div id="loading-spinner"><div class="spinner-icon"></div></div></div>',
                backdrop: 'static',
                keyboard: false,
                windowClass: 'loading',
                windowTopClass: 'top-loading',
                size: 'md'
              }));
        }

        $rootScope.updateTextLoading = function(text){
            $rootScope.loadingText = text;
        }

        $rootScope.hideLoading = function(){
            var loading = loadingsModals.pop();
            if(loading){
                $timeout(function(){loading.dismiss()},200)
            }  
        }

        var showingErroMessage = false;
        $rootScope.defaultErrorMessage = function(status){
            var modalInstance;
            if(!showingErroMessage){
                showingErroMessage = true;
                switch(status){
                    case 500:
                    case 400:
                        modalInstance = ModalHelper.error("Hubo un error interno en el servidor y su solicitud no ha podido ser procesada, disculpe las molestias");
                    break;
                    case 404:
                        modalInstance = ModalHelper.error("Falló la conexión con el servidor debido a un error inesperado, disculpe las molestias");
                    break;
                }
                modalInstance.result.then(function(){
                    showingErroMessage = false;
                })
                $rootScope.hideLoading();    
            }
        }       
    }
})();
